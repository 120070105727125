<!--Double navigation-->
<header>
  <!-- Sidebar navigation -->
  <mdb-sidenav #sidenav class="sn-bg-1" [fixed]="false">
    <logo>
      <li>
        <div class="d-flex justify-content-center">
          <!--<img src="assets/img/heraldica.png" class="img-fluid mt-2 white w-85">-->
          <img class="img-fluid mt-2 w-85 img-sidebar"  alt="" [src]="'data:image/jpg;base64,'+configuracion.logoSidebar" *ngIf="configuracion.logoSidebar">
        </div>
      </li>
      <!--/. Logo -->
    </logo>

    <hr style="border-color: white;">

    <links>
      <!-- Side navigation links -->
      <li>
        <perfect-scrollbar style="max-width: 600px; max-height: 350px;">
          <ul class="collapsible collapsible-accordion mt-0">
            <mdb-squeezebox [multiple]="false" aria-multiselectable="false">

              <!--SimpleLink -->
              <!--<mdb-item class="no-collase">
                <mdb-item-head mdbWavesEffect routerLink="admin-agenda" routerLinkActive="active">
                  <i class="far fa-hand-pointer"></i> Agenda
                </mdb-item-head>
                <mdb-item-body></mdb-item-body>
              </mdb-item>-->
              <!-- Simple link -->
              <!-- Simple link -->
              <mdb-item class="no-collase" *ngIf="showViewIfThereIsAccess('/app/bandeja_entrada')
              || showViewIfThereIsAccess('/app/tramitesMc') || showViewIfThereIsAccess('/app/recepcion')
              || showViewIfThereIsAccess('/app/nomina-cfdi')">
                <mdb-item-head *ngIf="showViewIfThereIsAccess('/app/bandeja_entrada')" mdbWavesEffect routerLink="bandeja_entrada" routerLinkActive="active">
                  <i class="far fa-hand-pointer"></i> Bandeja de entrada
                </mdb-item-head>
                <mdb-item-body></mdb-item-body>
                <mdb-item-head *ngIf="showViewIfThereIsAccess('/app/tramitesMc')" mdbWavesEffect routerLink="tramitesMc" routerLinkActive="active">
                  <i class="far fa-hand-pointer"></i> Trámites MC
                </mdb-item-head>
                <mdb-item-body></mdb-item-body>
                <mdb-item-head *ngIf="showViewIfThereIsAccess('/app/motivos_rechazo')" mdbWavesEffect routerLink="motivos_rechazo" routerLinkActive="active">
                  <i class="far fa-hand-pointer"></i> Catálogo de motivos de rechazo
                </mdb-item-head>
                <mdb-item-body></mdb-item-body>
                <!-- <mdb-item-head *ngIf="showViewIfThereIsAccess('/app/recepcion')" mdbWavesEffect routerLink="recepcion" routerLinkActive="active">
                  <i class="far fa-hand-pointer"></i> Recepción de CFDIs
                </mdb-item-head>
                <mdb-item-body></mdb-item-body> -->
                <!-- <mdb-item-head *ngIf="showViewIfThereIsAccess('/app/nomina-cfdi')" mdbWavesEffect routerLink="nomina-cfdi" routerLinkActive="active">
                  <i class="far fa-hand-pointer"></i> Recibos de Nómina
                </mdb-item-head>
                <mdb-item-body></mdb-item-body> -->
              </mdb-item>
              <mdb-item class="no-collase" *ngIf="showViewIfThereIsAccess('/app/donacion')">
                <mdb-item-head mdbWavesEffect routerLink="donacion" routerLinkActive="active">
                  <i class="fa fa-hand-pointer"></i> Programas de donativos
                </mdb-item-head>
                <mdb-item-body></mdb-item-body>
              </mdb-item>


              <!--Gestión de CFDI´s-->
              <!-- <mdb-item *ngIf="showViewIfThereIsAccess('/app/pool/levels') || showViewIfThereIsAccess('/app/pool/packs')
                || showViewIfThereIsAccess('/app/pool/grants') || showViewIfThereIsAccess('/app/pool/group-grants')
                || showViewIfThereIsAccess('/app/pool/bloodtypes') || showViewIfThereIsAccess('/app/pool/schedulepool')">
                <mdb-item-head mdbWavesEffect>
                  <i class="fa fa-inbox"></i> Catálogos de albercas
                </mdb-item-head>
                <mdb-item-body>
                  <ul>
                    <li *ngIf="showViewIfThereIsAccess('/app/pool/levels')">
                      <a href="#"
                        class="waves-effect"
                        routerLink="pool/levels"
                        routerLinkActive="active"
                        mdbWavesEffect>Niveles de natacion</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/pool/packs')">
                      <a href="#"
                        class="waves-effect"
                        routerLink="pool/packs"
                        routerLinkActive="active"
                        mdbWavesEffect>Paquetes</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/pool/grants')">
                      <a href="#"
                        class="waves-effect"
                        routerLink="pool/grants"
                        routerLinkActive="active"
                        mdbWavesEffect>Becas</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/pool/group-grants')">
                      <a href="#"
                        class="waves-effect"
                        routerLink="pool/group-grants"
                        routerLinkActive="active"
                        mdbWavesEffect>Becas Grupales</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/pool/bloodtypes')">
                      <a href="#"
                        class="waves-effect"
                        routerLink="pool/bloodtypes"
                        routerLinkActive="active"
                        mdbWavesEffect>Tipos de Sangre</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/pool/schedulepool')">
                      <a href="#"
                        class="waves-effect"
                        routerLink="pool/schedulepool"
                        routerLinkActive="active"
                        mdbWavesEffect>Horarios de Alberca</a>
                    </li>
                  </ul>
                </mdb-item-body>
              </mdb-item> -->
              <mdb-item *ngIf="showViewIfThereIsAccess('/app/control-cfdi') || showViewIfThereIsAccess('/app/act-datos-fiscales')
              || showViewIfThereIsAccess('/app/control-cfdi/individual/timbrado') || showViewIfThereIsAccess('/app/pagos')
              || showViewIfThereIsAccess('/app/recepcion') || showViewIfThereIsAccess('/app/nomina-cfdi')">
                <mdb-item-head mdbWavesEffect>
                  <i class="fa fa-inbox"></i> Gestión de CFDI´s
                </mdb-item-head>
                <mdb-item-body>
                  <ul>
                    <li *ngIf="showViewIfThereIsAccess('/app/control-cfdi')">
                      <a href="#" class="waves-effect" routerLink="control-cfdi" routerLinkActive="active" mdbWavesEffect>Control de CFDI's</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/act-datos-fiscales')">
                      <a href="#" class="waves-effect" routerLink="act-datos-fiscales" routerLinkActive="active" mdbWavesEffect>Actualizar datos fiscales</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/control-cfdi/individual/timbrado')">
                      <a href="#" class="waves-effect" routerLink="control-cfdi/individual/timbrado" routerLinkActive="active" mdbWavesEffect>Timbrado individual</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/pagos')">
                      <a href="#" class="waves-effect" routerLink="pagos" routerLinkActive="active" mdbWavesEffect>Comprobantes Fiscales de
                        Pagos</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/recepcion')">
                      <a href="#" class="waves-effect" routerLink="recepcion" routerLinkActive="active" dbWavesEffect>Recepción de CFDI's</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/nomina-cfdi')">
                      <a href="#" class="waves-effect" routerLink="nomina-cfdi" routerLinkActive="active" mdbWavesEffect>Recibos de Nómina</a>
                    </li>
                  </ul>
                </mdb-item-body>
              </mdb-item>


              <mdb-item *ngIf="showViewIfThereIsAccess('/app/realstate')" 
                class="no-collase" >
                <mdb-item-head mdbWavesEffect routerLink="realstate" routerLinkActive="active">
                  <i class="fa fa-hand-pointer"></i> Administración de inmobiliarias
                </mdb-item-head>
                <mdb-item-body></mdb-item-body>
              </mdb-item>

              <mdb-item *ngIf="showViewIfThereIsAccess('/app/transfer/notaries')" 
                class="no-collase" >
                <mdb-item-head mdbWavesEffect routerLink="transfer/notaries" routerLinkActive="active">
                  <i class="fa fa-hand-pointer"></i> Notarías
                </mdb-item-head>
                <mdb-item-body></mdb-item-body>
              </mdb-item>
              

              <!--Catalogo de albercas-->
              <mdb-item *ngIf="showViewIfThereIsAccess('/app/pool/levels') || showViewIfThereIsAccess('/app/pool/packs')
                || showViewIfThereIsAccess('/app/pool/grants') || showViewIfThereIsAccess('/app/pool/group-grants')
                || showViewIfThereIsAccess('/app/pool/bloodtypes') || showViewIfThereIsAccess('/app/pool/schedulepool')">
                <mdb-item-head mdbWavesEffect>
                  <i class="fa fa-inbox"></i> Catálogos de albercas
                </mdb-item-head>
                <mdb-item-body>
                  <ul>
                    <li *ngIf="showViewIfThereIsAccess('/app/pool/levels')">
                      <a href="#"
                        class="waves-effect"
                        routerLink="pool/levels"
                        routerLinkActive="active"
                        mdbWavesEffect>Niveles de natacion</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/pool/packs')">
                      <a href="#"
                        class="waves-effect"
                        routerLink="pool/packs"
                        routerLinkActive="active"
                        mdbWavesEffect>Paquetes</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/pool/grants')">
                      <a href="#"
                        class="waves-effect"
                        routerLink="pool/grants"
                        routerLinkActive="active"
                        mdbWavesEffect>Becas</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/pool/group-grants')">
                      <a href="#"
                        class="waves-effect"
                        routerLink="pool/group-grants"
                        routerLinkActive="active"
                        mdbWavesEffect>Becas Grupales</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/pool/bloodtypes')">
                      <a href="#"
                        class="waves-effect"
                        routerLink="pool/bloodtypes"
                        routerLinkActive="active"
                        mdbWavesEffect>Tipos de Sangre</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/pool/schedulepool')">
                      <a href="#"
                        class="waves-effect"
                        routerLink="pool/schedulepool"
                        routerLinkActive="active"
                        mdbWavesEffect>Horarios de Alberca</a>
                    </li>
                  </ul>
                </mdb-item-body>
              </mdb-item>

              <mdb-item *ngIf="showViewIfThereIsAccess('/app/cajaCODI')" class="no-collase" >
                <mdb-item-head mdbWavesEffect routerLink="cajaCODI" routerLinkActive="active">
                  <i class="fa fa-hand-pointer"></i> Configuración caja CODI
                </mdb-item-head>
                <mdb-item-body></mdb-item-body>
              </mdb-item>

              <!-- <mdb-item *ngIf="showViewIfThereIsAccess('/app/dashboard') || showViewIfThereIsAccess('/app/visor') || showViewIfThereIsAccess('/app/reportes') || showViewIfThereIsAccess('/app/bitacora')">
                <mdb-item-head mdbWavesEffect>
                  <i class="fa fa-bars"></i> Reportes
                </mdb-item-head>
                <mdb-item-body>
                  <ul>
                    <li *ngIf="showViewIfThereIsAccess('/app/dashboard')">
                      <a href="#" class="waves-effect"
                         routerLink="dashboard"
                         routerLinkActive="active"
                         mdbWavesEffect
                      > Dashboard</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/visor')">
                      <a href="#" class="waves-effect"
                         routerLink="visor"
                         routerLinkActive="active"
                         mdbWavesEffect
                      > Visor de trámites</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/reportes')">
                      <a href="#" class="waves-effect"
                         routerLink="reportes"
                         routerLinkActive="active"
                         mdbWavesEffect
                      > Reportes de trámites</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/bitacora')">
                      <a href="#" class="waves-effect"
                         routerLink="bitacora"
                         routerLinkActive="active"
                         mdbWavesEffect
                      > Bitácora de movimientos</a>
                    </li>
                  </ul>
                </mdb-item-body>
              </mdb-item> -->

              <!-- <mdb-item *ngIf="showViewIfThereIsAccess('/app/actualiza_datadic') || showViewIfThereIsAccess('/app/configura_tramite_vacaciones') ||
                               showViewIfThereIsAccess('/app/actualiza_datadic') || showViewIfThereIsAccess('/app/cancelar_pase_caja') ||
                               showViewIfThereIsAccess('/app/menuEmpleados') || showViewIfThereIsAccess('/app/modificar_acciones')">
                <mdb-item-head mdbWavesEffect>
                  <i class="fa fa-bars"></i> Administración de trámites
                </mdb-item-head>
                <mdb-item-body>
                  <ul>
                    <li *ngIf="showViewIfThereIsAccess('/app/cancelar_pase_caja')">
                      <a href="#" class="waves-effect"
                         routerLink="cancelar_pase_caja"
                         routerLinkActive="active"
                         mdbWavesEffect
                      > Cancelar pases de caja</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/menuEmpleados')">
                      <a href="#" class="waves-effect"
                         routerLink="menuEmpleados"
                         routerLinkActive="active"
                         mdbWavesEffect
                      > Menú de empleados</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/actualiza_datadic')">
                      <a href="#" class="waves-effect"
                         routerLink="actualiza_datadic"
                         routerLinkActive="active"
                         mdbWavesEffect
                      > Actualización datos adicionales</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/configura_tramite_vacaciones')">
                      <a href="#" class="waves-effect"
                         routerLink="configura_tramite_vacaciones"
                         routerLinkActive="active"
                         mdbWavesEffect
                      > Configuración trámite de vacaciones</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/modificar_acciones')">
                      <a href="#" class="waves-effect"
                         routerLink="modificar_acciones"
                         routerLinkActive="active"
                         mdbWavesEffect
                      > Modificación de acciones</a>
                    </li>
                  </ul>
                </mdb-item-body>
              </mdb-item> -->
              <!--
              <mdb-item>
                <mdb-item-head mdbWavesEffect>
                  <i class="fa fa-bars"></i> Configuración del portal
                </mdb-item-head>
                <mdb-item-body>
                  <ul>
                    <li *ngIf="showViewIfThereIsAccess('/app/configuracion_chat')">
                      <a href="#" class="waves-effect"
                      routerLink="configuracion_chat"
                      routerLinkActive="active"
                      mdbWavesEffect
                      > Configuración del chat</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/galeria_eventos')">
                      <a href="#" class="waves-effect"
                      routerLink="galeria_eventos"
                      routerLinkActive="active"
                      mdbWavesEffect
                      > Galería eventos</a>
                    </li>
                  </ul>
                </mdb-item-body>
              </mdb-item>

              <mdb-item class="no-collase" *ngIf="showViewIfThereIsAccess('/app/bandeja_entrada')">
                <mdb-item-head mdbWavesEffect routerLink="bandeja_entrada" routerLinkActive="active">
                  <i class="far fa-hand-pointer"></i> Bandeja de entrada
                </mdb-item-head>
                <mdb-item-body></mdb-item-body>
                <mdb-item-head mdbWavesEffect routerLink="tramitesMc" routerLinkActive="active">
                  <i class="far fa-hand-pointer"></i> Tramites Mc
                </mdb-item-head>
                <mdb-item-body></mdb-item-body>
                <mdb-item-head mdbWavesEffect routerLink="recepcion" routerLinkActive="active">
                  <i class="far fa-hand-pointer"></i> Recepción de CFDIs
                </mdb-item-head>
                <mdb-item-body></mdb-item-body>
                <mdb-item-head mdbWavesEffect routerLink="nomina-cfdi" routerLinkActive="active">
                  <i class="far fa-hand-pointer"></i> Recibos de Nómina
                </mdb-item-head>
                <mdb-item-body></mdb-item-body>
              </mdb-item>
              <mdb-item class="no-collase">
                <mdb-item-head mdbWavesEffect routerLink="cancelar_pase_caja" routerLinkActive="active">
                  <i class="far fa-hand-pointer"></i> Cancelar pases de caja
                </mdb-item-head>
                <mdb-item-body></mdb-item-body>
              </mdb-item>
-->
              <!-- Contratistas  -->
              <mdb-item *ngIf="showViewIfThereIsAccess('/app/contratistas/camaras-colegios') || showViewIfThereIsAccess('/app/contratistas/especialidades')
                || showViewIfThereIsAccess('/app/contratistas/observaciones') || showViewIfThereIsAccess('/app/contratistas/licitaciones') || showViewIfThereIsAccess('/app/contratistas/mod-correo')
                || showViewIfThereIsAccess('/app/contratistas/reportes') || showViewIfThereIsAccess('/app/contratistas/seleccion')
                || showViewIfThereIsAccess('/app/contratistas/solicitudes') || showViewIfThereIsAccess('/app/contratistas/solicitud-actualizacion')">
                <mdb-item-head mdbWavesEffect>
                  <i class="fa fa-inbox"></i> Contratistas
                </mdb-item-head>
                <mdb-item-body>
                  <ul>
                    <li *ngIf="showViewIfThereIsAccess('/app/contratistas/camaras-colegios')">
                      <a href="#"
                        class="waves-effect"
                        routerLink="contratistas/camaras-colegios"
                        routerLinkActive="active"
                        mdbWavesEffect>Catálogo
                        de Cámaras y Colegios</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/contratistas/especialidades')">
                      <a href="#"
                        class="waves-effect"
                        routerLink="contratistas/especialidades"
                        routerLinkActive="active"
                        mdbWavesEffect>Catálogo
                        de Especialidades</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/contratistas/observaciones')">
                      <a href="#"
                        class="waves-effect"
                        routerLink="contratistas/observaciones"
                        routerLinkActive="active"
                        mdbWavesEffect>Contratistas
                        con Observaciones</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/contratistas/licitaciones')">
                      <a href="#"
                        class="waves-effect"
                        routerLink="contratistas/licitaciones"
                        routerLinkActive="active"
                        mdbWavesEffect>Licitaciones</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/contratistas/mod-correo')">
                      <a href="#"
                        class="waves-effect"
                        routerLink="contratistas/mod-correo"
                        routerLinkActive="active"
                        mdbWavesEffect>Modificar
                        Correo</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/contratistas/reportes')">
                      <a href="#"
                        class="waves-effect"
                        routerLink="contratistas/reportes"
                        routerLinkActive="active"
                        mdbWavesEffect>Reportes</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/contratistas/seleccion')">
                      <a href="#"
                        class="waves-effect"
                        routerLink="contratistas/seleccion"
                        routerLinkActive="active"
                        mdbWavesEffect>Selección de Contratistas</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/contratistas/solicitudes')">
                      <a href="#"
                        class="waves-effect"
                        routerLink="contratistas/solicitudes"
                        routerLinkActive="active"
                        mdbWavesEffect>Inscripción o Refrendo</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/contratistas/solicitud-actualizacion')">
                      <a href="#"
                        class="waves-effect"
                        routerLink="contratistas/solicitud-actualizacion"
                        routerLinkActive="active"
                        mdbWavesEffect>Solicitudes de Actualización</a>
                    </li>
                  </ul>
                </mdb-item-body>
              </mdb-item>

              <mdb-item *ngIf="showViewIfThereIsAccess('/app/consultas/traslado')">
                <mdb-item-head mdbWavesEffect>
                  <i class="fa fa-inbox"></i> Consultas
                </mdb-item-head>
                <mdb-item-body>
                  <ul>
                    <li>
                      <a href="#" class="waves-effect" routerLink="consultas/traslado" mdbWavesEffect>Traslado</a>
                    </li>
                  </ul>
                </mdb-item-body>
              </mdb-item>

              <mdb-item *ngIf="showViewIfThereIsAccess('/app/dashboard') || showViewIfThereIsAccess('/app/visor') || showViewIfThereIsAccess('/app/reportes') || showViewIfThereIsAccess('/app/bitacora')">
                <mdb-item-head mdbWavesEffect>
                  <i class="fa fa-bars"></i> Reportes
                </mdb-item-head>
                <mdb-item-body>
                  <ul>
                    <li *ngIf="showViewIfThereIsAccess('/app/dashboard')">
                      <a href="#" class="waves-effect"
                      routerLink="dashboard"
                      routerLinkActive="active"
                      mdbWavesEffect
                      > Dashboard</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/visor')">
                      <a href="#" class="waves-effect"
                      routerLink="visor"
                      routerLinkActive="active"
                      mdbWavesEffect
                      > Visor de trámites</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/reportes')">
                      <a href="#" class="waves-effect"
                      routerLink="reportes"
                      routerLinkActive="active"
                      mdbWavesEffect
                      > Reportes de trámites</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/bitacora')">
                      <a href="#" class="waves-effect"
                      routerLink="bitacora"
                      routerLinkActive="active"
                      mdbWavesEffect
                      > Bitácora de movimientos</a>
                    </li>
                  </ul>
                </mdb-item-body>
              </mdb-item>

              <mdb-item *ngIf="showViewIfThereIsAccess('/app/actualiza_datadic') || showViewIfThereIsAccess('/app/configura_tramite_vacaciones') ||
                               showViewIfThereIsAccess('/app/actualiza_datadic') || showViewIfThereIsAccess('/app/cancelar_pase_caja') ||
                               showViewIfThereIsAccess('/app/menuEmpleados') || showViewIfThereIsAccess('/app/modificar_acciones')">
                <mdb-item-head mdbWavesEffect>
                  <i class="fa fa-bars"></i> Administración de trámites
                </mdb-item-head>
                <mdb-item-body>
                  <ul>
                    <li *ngIf="showViewIfThereIsAccess('/app/cancelar_pase_caja')">
                      <a href="#" class="waves-effect"
                      routerLink="cancelar_pase_caja"
                      routerLinkActive="active"
                      mdbWavesEffect
                      > Cancelar pases de caja</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/menuEmpleados')">
                      <a href="#" class="waves-effect"
                      routerLink="menuEmpleados"
                      routerLinkActive="active"
                      mdbWavesEffect
                      > Menú de empleados</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/actualiza_datadic')">
                      <a href="#" class="waves-effect"
                      routerLink="actualiza_datadic"
                      routerLinkActive="active"
                      mdbWavesEffect
                      > Actualización datos adicionales</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/configura_tramite_vacaciones')">
                      <a href="#" class="waves-effect"
                      routerLink="configura_tramite_vacaciones"
                      routerLinkActive="active"
                      mdbWavesEffect
                      > Configuración trámite de vacaciones</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/modificar_acciones')">
                      <a href="#" class="waves-effect"
                      routerLink="modificar_acciones"
                      routerLinkActive="active"
                      mdbWavesEffect
                      > Modificación de acciones</a>
                    </li>
                  </ul>
                </mdb-item-body>
              </mdb-item>
              <!--
              <mdb-item>
                <mdb-item-head mdbWavesEffect>
                  <i class="fa fa-bars"></i> Configuración del portal
                </mdb-item-head>
                <mdb-item-body>
                  <ul>
                    <li *ngIf="showViewIfThereIsAccess('/app/configuracion_chat')">
                      <a href="#" class="waves-effect"
                      routerLink="configuracion_chat"
                      routerLinkActive="active"
                      mdbWavesEffect
                      > Configuración del chat</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/galeria_eventos')">
                      <a href="#" class="waves-effect"
                      routerLink="galeria_eventos"
                      routerLinkActive="active"
                      mdbWavesEffect
                      > Galería eventos</a>
                    </li>
                  </ul>
                </mdb-item-body>
              </mdb-item>

              <mdb-item class="no-collase" *ngIf="showViewIfThereIsAccess('/app/dashboard')">
                <mdb-item-head mdbWavesEffect routerLink="dashboard" routerLinkActive="active">
                  <i class="far fa-hand-pointer"></i> Dashboard
                </mdb-item-head>
              </mdb-item>
              <mdb-item class="no-collase" *ngIf="showViewIfThereIsAccess('/app/bandeja_entrada')">
                <mdb-item-head mdbWavesEffect routerLink="bandeja_entrada" routerLinkActive="active">
                  <i class="far fa-hand-pointer"></i> Bandeja de entrada
                </mdb-item-head>
                <mdb-item-body></mdb-item-body>
              </mdb-item>
              <mdb-item class="no-collase">
                <mdb-item-head mdbWavesEffect routerLink="tramitesMc" routerLinkActive="active">
                  <i class="far fa-hand-pointer"></i> Tramites Mc
                </mdb-item-head>
                <mdb-item-body></mdb-item-body>
              </mdb-item>
              <mdb-item class="no-collase">
                <mdb-item-head mdbWavesEffect routerLink="cancelar_pase_caja" routerLinkActive="active">
                  <i class="far fa-hand-pointer"></i> Cancelar pases de caja
                </mdb-item-head>
                <mdb-item-body></mdb-item-body>
              </mdb-item>
              <mdb-item class="no-collase">
                <mdb-item-head mdbWavesEffect routerLink="cancelar_pase_caja" routerLinkActive="active">
                  <i class="far fa-hand-pointer"></i> Cancelar pases de caja
                </mdb-item-head>
                <mdb-item-body></mdb-item-body>
              </mdb-item>
              <mdb-item class="no-collase">
                <mdb-item-head mdbWavesEffect routerLink="tramitesMc" routerLinkActive="active">
                  <i class="far fa-hand-pointer"></i> Tramites Mc
                </mdb-item-head>
                <mdb-item-body></mdb-item-body>
              </mdb-item>
              <mdb-item class="no-collase" *ngIf="showViewIfThereIsAccess('/app/visor')">
                <mdb-item-head mdbWavesEffect routerLink="visor" routerLinkActive="active">
                  <i class="far fa-hand-pointer"></i> Visor de trámites
                </mdb-item-head>
                <mdb-item-body></mdb-item-body>
              </mdb-item>
              <mdb-item class="no-collase" *ngIf="showViewIfThereIsAccess('/app/reportes')">
                <mdb-item-head mdbWavesEffect routerLink="reportes" routerLinkActive="active">
                  <i class="far fa-list-alt"></i> Reportes de trámites
                </mdb-item-head>
                <mdb-item-body></mdb-item-body>
              </mdb-item>
              <mdb-item class="no-collase" >
                <mdb-item-head mdbWavesEffect routerLink="menuEmpleados" routerLinkActive="active">
                  <i class="far fa-list-alt"></i> Menú opciones empleado
                </mdb-item-head>
                <mdb-item-body></mdb-item-body>
              </mdb-item>
              <mdb-item class="no-collase" *ngIf="showViewIfThereIsAccess('/app/actualiza_datadic')">
                <mdb-item-head mdbWavesEffect routerLink="actualiza_datadic" routerLinkActive="active">
                  <i class="far fa-list-alt"></i> Actualizar Datos Adicionales
                </mdb-item-head>
                <mdb-item-body></mdb-item-body>
              </mdb-item>

              <mdb-item class="no-collase" *ngIf="showViewIfThereIsAccess('/app/galeria_eventos')">
                <mdb-item-head mdbWavesEffect routerLink="galeria_eventos" routerLinkActive="active">
                  <i class="far fa-list-alt"></i> Galería de Eventos
                </mdb-item-head>
                <mdb-item-body></mdb-item-body>
              </mdb-item>
              <mdb-item class="no-collase" *ngIf="showViewIfThereIsAccess('/app/configura_tramite_vacaciones')">
                <mdb-item-head mdbWavesEffect routerLink="configura_tramite_vacaciones" routerLinkActive="active">
                  <i class="far fa-list-alt"></i> Configurar Trámite de Vacaciones
                </mdb-item-head>
                <mdb-item-body></mdb-item-body>
              </mdb-item>
              <mdb-item class="no-collase" *ngIf="showViewIfThereIsAccess('/app/actualiza_datadic')">
                <mdb-item-head mdbWavesEffect routerLink="modificar_acciones" routerLinkActive="active">
                  <i class="far fa-list-alt"></i> Modificación de Acciones
                </mdb-item-head>
                <mdb-item-body></mdb-item-body>
              </mdb-item>
            -->
              <!-- Simple link -->
              <!-- Collapsible link -->
              <mdb-item *ngIf="showViewIfThereIsAccess('/app/adm_roles')||showViewIfThereIsAccess('/app/adm_usuarios')||showViewIfThereIsAccess('/app/adm_areas')">
                <mdb-item-head mdbWavesEffect>
                  <i class="fa fa-inbox"></i> Administración de usuarios
                </mdb-item-head>
                <mdb-item-body>
                  <ul>
                    <li *ngIf="showViewIfThereIsAccess('/app/adm_roles')">
                      <a href="#" class="waves-effect"
                         routerLink="adm_roles"
                         routerLinkActive="active"
                         mdbWavesEffect>Catálogo de roles</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/adm_usuarios')">
                      <a href="#" class="waves-effect"
                         routerLink="adm_usuarios"
                         routerLinkActive="active"
                         mdbWavesEffect>Catálogo de usuarios</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/adm_areas')">
                      <a href="#" class="waves-effect"
                         routerLink="adm_areas"
                         routerLinkActive="active"
                         mdbWavesEffect>Catálogo de áreas</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/adm_organizaciones')">
                      <a href="#" class="waves-effect"
                         routerLink="adm_organizaciones"
                         routerLinkActive="active"
                         mdbWavesEffect>Catálogo de organizaciones</a>
                    </li>
                  </ul>
                </mdb-item-body>
              </mdb-item>

              <mdb-item *ngIf="showViewIfThereIsAccess('/app/catalogo_tramites')
                    ||showViewIfThereIsAccess('/app/catalogo_categorias')
                    ||showViewIfThereIsAccess('/app/catalogo_tramites/sub-categorias')
                    ||showViewIfThereIsAccess('/app/carga_masiva')
                    ||showViewIfThereIsAccess('/app/bitacora-ficha-tecnica')">
                <mdb-item-head mdbWavesEffect>
                  <i class="fa fa-inbox"></i> Configuración de trámites
                </mdb-item-head>
                <mdb-item-body>
                  <ul>
                    <li *ngIf="showViewIfThereIsAccess('/app/catalogo_tramites')">
                      <a href="#" class="waves-effect"
                         routerLink="catalogo_tramites"
                         routerLinkActive="active"
                         [routerLinkActiveOptions]="{exact:true}"
                         mdbWavesEffect>Catálogo de trámites</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/catalogo_categorias')">
                      <a href="#" class="waves-effect"
                         routerLink="catalogo_categorias"
                         routerLinkActive="active"
                         mdbWavesEffect>Catálogo de categorías</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/catalogo_tramites/sub-categorias')">
                      <a href="#" class="waves-effect"
                         routerLink="catalogo_tramites/sub-categorias"
                         routerLinkActive="active"
                         [routerLinkActiveOptions]="{exact:true}"
                         mdbWavesEffect>Catálogo de sub categorías</a>
                    </li>
                    <!--<li>
                      <a href="#" class="waves-effect"
                         routerLink="catalogo_documentos"
                         routerLinkActive="active"
                         mdbWavesEffect>Catálogo de documentos</a>
                    </li>-->
                    <li *ngIf="showViewIfThereIsAccess('/app/carga_masiva')">
                      <a href="#" class="waves-effect"
                         routerLink="carga_masiva"
                         routerLinkActive="active"
                         mdbWavesEffect>Carga masiva de trámites</a>
                    </li>
                    <li >
                      <a href="#" class="waves-effect"
                         routerLink="cat-resolutivos-plant"
                         routerLinkActive="active"
                         mdbWavesEffect>Catálogo de Plantillas Html</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/catalogo_resolutivos')">
                      <a href="#" class="waves-effect"
                         routerLink="catalogo_resolutivos"
                         routerLinkActive="active"
                         mdbWavesEffect>Catálogo de Resolutivos</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/catalogo_seccionresolutivos')">
                      <a href="#" class="waves-effect"
                         routerLink="catalogo_seccionresolutivos"
                         routerLinkActive="active"
                         mdbWavesEffect>Catálogo de Secciones Resolutivos</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/catalogo_etiqresolutivos')">
                      <a href="#" class="waves-effect"
                         routerLink="catalogo_etiqresolutivos"
                         routerLinkActive="active"
                         mdbWavesEffect>Catálogo de Etiquetas Resolutivos</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/bitacora-ficha-tecnica')">
                      <a href="#" class="waves-effect"
                         routerLink="bitacora-ficha-tecnica"
                         routerLinkActive="active"
                         [routerLinkActiveOptions]="{exact:true}"
                         mdbWavesEffect>Bitacora Fichas Tecnicas</a>
                    </li>
                    <li>
                        <a href="#" class="waves-effect"
                         routerLink="cnf-folio"
                         routerLinkActive="active"
                         mdbWavesEffect>Catálogo de folios</a>
                    </li>
                    <li>
                      <a href="#" class="waves-effect"
                       routerLink="ficha-tecnica"
                       routerLinkActive="active"
                       mdbWavesEffect>Fichas Técnicas</a>
                  </li>
                  </ul>
                </mdb-item-body>
              </mdb-item>

              <mdb-item *ngIf="showViewIfThereIsAccess('/app/bpm_rcs_tramites/flujos')||showViewIfThereIsAccess('/app/bpm_vu_tramites/flujos')||showViewIfThereIsAccess('/app/configuracion_rc')||
              showViewIfThereIsAccess('/app/bpm_ti_tramites/flujos')||showViewIfThereIsAccess('/app/evaluacion-ciudadana')||showViewIfThereIsAccess('/app/bpm_rcs_tramites/plantillas')||
              showViewIfThereIsAccess('/app/bpm_vu_tramites/plantillas')||showViewIfThereIsAccess('/app/bpm_rcs_tramites/articulos')||showViewIfThereIsAccess('/app/bpm_vu_tramites/articulos')||
              showViewIfThereIsAccess('/app/catalogo_zonas')||showViewIfThereIsAccess('/app/bpm_rcs_tramites/reasignacion')">
                <mdb-item-head mdbWavesEffect>
                  <i class="fa fa-inbox"></i> Administración de flujos
                </mdb-item-head>
                <mdb-item-body>
                  <ul>
                    <li *ngIf="showViewIfThereIsAccess('/app/bpm_rcs_tramites/flujos')">
                      <a href="#" class="waves-effect"
                         routerLink="bpm_rcs_tramites/flujos"
                         routerLinkActive="active"
                         mdbWavesEffect>Reporte Ciudadano</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/bpm_vu_tramites/flujos')">
                      <a href="#" class="waves-effect"
                         routerLink="bpm_vu_tramites/flujos"
                         routerLinkActive="active"
                         mdbWavesEffect>Ventanilla Única</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/configuracion_rc')">
                      <a href="#" class="waves-effect"
                         routerLink="configuracion_rc"
                         routerLinkActive="active"
                         mdbWavesEffect>Reporte ciudadano RCS</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/configuracion_rda')">
                      <a href="#" class="waves-effect"
                         routerLink="configuracion_rda"
                         routerLinkActive="active"
                         mdbWavesEffect>Configuración de reporte de datos adicionales</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/bpm_ti_tramites/flujos')">
                      <a href="#" class="waves-effect"
                         routerLink="bpm_ti_tramites/flujos"
                         routerLinkActive="active"
                         mdbWavesEffect>Trámites informativos</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/evaluacion-ciudadana')">
                      <a href="#" class="waves-effect"
                         routerLink="evaluacion-ciudadana"
                         routerLinkActive="active"
                         mdbWavesEffect>Evaluación ciudadana</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/bpm_rcs_tramites/plantillas')">
                      <a href="#" class="waves-effect"
                         routerLink="bpm_rcs_tramites/plantillas"
                         routerLinkActive="active"
                         mdbWavesEffect>Catálogo de plantillas RC</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/bpm_vu_tramites/plantillas')">
                      <a href="#" class="waves-effect"
                         routerLink="bpm_vu_tramites/plantillas"
                         routerLinkActive="active"
                         mdbWavesEffect>Catálogo de plantillas VU</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/bpm_rcs_tramites/articulos')">
                      <a href="#" class="waves-effect"
                         routerLink="bpm_rcs_tramites/articulos"
                         routerLinkActive="active"
                         mdbWavesEffect>Catálogo de articulos RC</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/bpm_vu_tramites/articulos')">
                      <a href="#" class="waves-effect"
                         routerLink="bpm_vu_tramites/articulos"
                         routerLinkActive="active"
                         mdbWavesEffect>Catálogo de articulos VU</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/catalogo_zonas')">
                      <a href="#" class="waves-effect"
                         routerLink="catalogo_zonas"
                         routerLinkActive="active"
                         mdbWavesEffect>Catálogo de zonas</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/bpm_rcs_tramites/reasignacion')">
                      <a href="#" class="waves-effect"
                         routerLink="bpm_rcs_tramites/reasignacion"
                         routerLinkActive="active"
                         mdbWavesEffect>Reasignación</a>
                    </li>
                  </ul>
                </mdb-item-body>
              </mdb-item>

              <!-- Collapsible link -->
              <mdb-item *ngIf="showViewIfThereIsAccess('/app/ver-expediente-electronico')||showViewIfThereIsAccess('/app/ver-expediente-electronico')">
                <mdb-item-head mdbWavesEffect>
                  <i class="fa fa-inbox"></i>Expediente Electrónico
                </mdb-item-head>
                <mdb-item-body>
                  <ul>
                    <li *ngIf="showViewIfThereIsAccess('/app/ver-expediente-electronico')">
                      <a href="#" class="waves-effect"
                         routerLink="ver-expediente-electronico"
                         routerLinkActive="active"
                         mdbWavesEffect>Expediente electrónico</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/ver-expediente-electronico')">
                      <a href="#" class="waves-effect"
                         routerLink="expediente-electronico"
                         routerLinkActive="active"
                         mdbWavesEffect>Documentos expediente</a>
                    </li>
                  </ul>
                </mdb-item-body>
              </mdb-item>

              <mdb-item *ngIf="showViewIfThereIsAccess('/app/configuracion_general')||showViewIfThereIsAccess('/app/banners') ||
                               showViewIfThereIsAccess('/app/noticias') || showViewIfThereIsAccess('/app/configuracion_mapa_calor') ||
                               showViewIfThereIsAccess('/app/banners') || showViewIfThereIsAccess('/app/configuracion_correo') ||
                               showViewIfThereIsAccess('/app/calendario/institucional') || showViewIfThereIsAccess('/app/calendario/configuracion') ||
                               showViewIfThereIsAccess('/app/galeria_eventos')">
                <mdb-item-head mdbWavesEffect>
                  <i class="fa fa-inbox"></i> Configuración del portal
                </mdb-item-head>
                <mdb-item-body>
                  <ul>
                    <li *ngIf="showViewIfThereIsAccess('/app/configuracion_general')">
                      <a href="#" class="waves-effect"
                         routerLink="configuracion_general"
                         routerLinkActive="active"
                         mdbWavesEffect>Configuración general</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/configuracion_correo')">
                      <a href="#" class="waves-effect"
                         routerLink="configuracion_correo"
                         routerLinkActive="active"
                         mdbWavesEffect>Configuración del correo</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/banners')">
                      <a href="#" class="waves-effect"
                         routerLink="banners"
                         routerLinkActive="active"
                         mdbWavesEffect>Avisos del portal</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/noticias')">
                      <a href="#" class="waves-effect"
                         routerLink="noticias"
                         routerLinkActive="active"
                         mdbWavesEffect>Noticias y enlaces</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/configuracion_mapa_calor')">
                      <a href="#" class="waves-effect"
                         routerLink="configuracion_mapa_calor"
                         routerLinkActive="active"
                         mdbWavesEffect>Colores para mapa de calor</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/calendario/institucional')">
                      <a href="#" class="waves-effect"
                         routerLink="calendario/institucional"
                         routerLinkActive="active"
                         mdbWavesEffect>Configuración Calendario</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/calendario/configuracion')">
                      <a href="#" class="waves-effect"
                         routerLink="calendario/configuracion"
                         routerLinkActive="active"
                         mdbWavesEffect>Eventos Calendario</a>
                    </li>

                    <li *ngIf="showViewIfThereIsAccess('/app/configuracion_chat')">
                      <a href="#" class="waves-effect"
                         routerLink="configuracion_chat"
                         routerLinkActive="active"
                         mdbWavesEffect
                      > Configuración del chat</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/galeria_eventos')">
                      <a href="#" class="waves-effect"
                         routerLink="galeria_eventos"
                         routerLinkActive="active"
                         mdbWavesEffect
                      > Galería eventos</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/configuracion_nueva_accion')">
                      <a href="#" class="waves-effect"
                         routerLink="configuracion_nueva_accion"
                         routerLinkActive="active"
                         mdbWavesEffect>Acciones de sistema externo</a>
                    </li>
                  </ul>
                </mdb-item-body>
              </mdb-item>

              <mdb-item *ngIf="showViewIfThereIsAccess('/app/adeudos') ||
               showViewIfThereIsAccess('/app/carga_masiva_predios')  ||
                showViewIfThereIsAccess('/app/consolidacion_pagos')">
                <mdb-item-head mdbWavesEffect>
                  <i class="fa fa-inbox"></i> Configuración de predial
                </mdb-item-head>
                <mdb-item-body>
                  <ul>
                    <li *ngIf="showViewIfThereIsAccess('/app/carga_masiva_predios')">
                      <a href="#" class="waves-effect"
                         routerLink="carga_masiva_predios"
                         routerLinkActive="active"
                         mdbWavesEffect>Carga de predios</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/adeudos')">
                      <a href="#" class="waves-effect"
                         routerLink="adeudos"
                         routerLinkActive="active"
                         mdbWavesEffect>Carga de adeudos</a>
                    </li>
                    <li *ngIf="showViewIfThereIsAccess('/app/consolidacion_pagos')">
                      <a href="#" class="waves-effect"
                         routerLink="consolidacion_pagos"
                         routerLinkActive="active"
                         mdbWavesEffect>Consolidación de pagos</a>
                    </li>
                    <li>
                      <a href="#" class="waves-effect" routerLink="consulta-pagos/consolidacion-cie" mdbWavesEffect>Consolidación Cie</a>
                    </li>
                  </ul>
                </mdb-item-body>
              </mdb-item>

              <mdb-item *ngIf="showViewIfThereIsAccess('/app/proveedores/admin/guidelines') || showViewIfThereIsAccess('/app/proveedores/admin/codeethics')
              || showViewIfThereIsAccess('/app/proveedores/admin/formats')
              || showViewIfThereIsAccess('/app/proveedores/admin/tender') || showViewIfThereIsAccess('/app/proveedores/admin/requestRegistrationAndEndorsement')">
                <mdb-item-head mdbWavesEffect>
                  <i class="fa fa-inbox"></i> Proveedores
                </mdb-item-head>
                <mdb-item-body>
                  <ul>
                    <li *ngIf="showViewIfThereIsAccess('/app/proveedores/admin/formats')">
                      <a href="#"
                        class="waves-effect"
                        routerLink="proveedores/admin/formats"
                        routerLinkActive="active"
                        mdbWavesEffect>
                        Catálogo de formatos
                      </a>
                    </li>
                  </ul>
                  <ul>
                    <li *ngIf="showViewIfThereIsAccess('/app/proveedores/admin/guidelines')">
                      <a href="#" class="waves-effect" routerLink="." (click)="generatePublicFileWith64(2)" mdbWavesEffect>
                        Lineamientos para proveedores <!--de la emisión de cotizaciones y recepción de facturas-->
                      </a>
                    </li>
                  </ul>
                  <ul>
                    <li *ngIf="showViewIfThereIsAccess('/app/proveedores/admin/codeethics')">
                      <a href="#" class="waves-effect" routerLink="." (click)="generatePublicFileWith64(1)" mdbWavesEffect>
                        Código de ética
                      </a>
                    </li>
                  </ul>
                  <ul>
                    <li *ngIf="showViewIfThereIsAccess('/app/proveedores/admin/tender')">
                      <a href="#"
                        class="waves-effect"
                        routerLink="proveedores/admin/tender"
                        routerLinkActive="active"
                        mdbWavesEffect>
                        Licitaciones Publicas
                      </a>
                    </li>
                  </ul>
                  <ul>
                    <li *ngIf="showViewIfThereIsAccess('/app/proveedores/admin/requestRegistrationAndEndorsement')">
                      <a href="#"
                        class="waves-effect"
                        routerLink="proveedores/admin/requestRegistrationAndEndorsement"
                        routerLinkActive="active"
                        mdbWavesEffect>
                        Solicitudes de Alta y Refrendo
                      </a>
                    </li>
                  </ul>
                </mdb-item-body>
              </mdb-item>

              <!--
                            <mdb-item class="no-collase" *ngIf="showViewIfThereIsAccess('/app/configuracion_chat')">
                              <mdb-item-head mdbWavesEffect routerLink="configuracion_chat" routerLinkActive="active">
                                <i class="far fa-hand-pointer"></i> Configuración del chat
                              </mdb-item-head>
                              <mdb-item-body></mdb-item-body>
                            </mdb-item>

                            <mdb-item class="no-collase" *ngIf="showViewIfThereIsAccess('/app/bitacora')">
                              <mdb-item-head mdbWavesEffect routerLink="bitacora" routerLinkActive="active">
                                <i class="far fa-hand-pointer"></i> Bitácora de movimientos
                              </mdb-item-head>
                              <mdb-item-body></mdb-item-body>
                            </mdb-item>
                          -->


              <!-- Simple link -->
              <!--mdb-item class="no-collase">
                <mdb-item-head mdbWavesEffect (click)="signOut()">
                  <i class="fas fa-sign-out-alt"></i> <span class="text-danger"> Cerrar sesión</span>
                </mdb-item-head>
                <mdb-item-body></mdb-item-body>
              </mdb-item-->
            </mdb-squeezebox>
          </ul>
        </perfect-scrollbar>
      </li>
      <!--/. Side navigation links -->
    </links>
    <div class="sidenav-bg mask-strong"></div>
  </mdb-sidenav>
  <!--/. Sidebar navigation -->

  <!-- Navbar -->
  <mdb-navbar SideClass="navbar fixed-top navbar-toggleable-md navbar-expand-lg scrolling-navbar double-nav"
              [containerInside]="false">

    <navlinks class="navbar-container">
      <!-- SideNav slide-out button -->
      <div class="float-left">
        <a (click)="sidenav.show()" class="button-collapse hidden-nav-button-collapse">
          <i class="fa fa-bars"></i>
        </a>
      </div>
      <!--/. SideNav slide-out button -->
    </navlinks>

    <navlinks>
      <ul class="nav navbar-nav nav-flex-icons ml-auto ie-double-nav ie-hidden-double-nav">
        <li class="nav-item dropdown centrar-vertical ml-3"
            dropdown
            #dropdown='bs-dropdown'
            *ngIf="user">
          <a dropdownToggle mdbWavesEffect mti-color-theme type="button" (click)="setShowBoxNotifications()" class="nav-link">
            <i title="Notificaciones" class="fas fa-bell"></i><span class="caret"></span>
          </a>
          <span class="counter-notifications-admin"
                *ngIf="notificationsCount > 0">{{notificationsCount}}</span>
          <ul *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu"
              style="left: -6rem; width: 300px !important;">
            <perfect-scrollbar style="max-height: 255px">
              <ul class="list-group">
                <li class="list-group-item text-muted px-2 py-1 cursor-pointer"
                    *ngFor="let notification of notifications"
                    (click)="dropdown.show(); seenNotification(notification, false);">
                  <small
                    class="float-right"><strong>{{notification?.fecha_creacion?.$date | date: 'dd/MM/yyyy hh:mm a' }}</strong>
                  </small>
                  <div class="clearfix"></div>
                  <div class="d-flex align-items-center">
                    <small>
                      {{notification.detalle + " "}}
                      <a *ngIf="notification.app_path != null && notification.app_path != undefined"
                      href="{{notification.app_path}}"
                         class="position-relative">Ir a trámite</a>
                    </small>
                    <div class="ml-1" *ngIf="notification.fecha_visto==null" style="width: 10px;min-width: 10px;max-width: 10px;height: 10px;min-height: 10px;max-height: 10px;background-color: rgb(0, 81, 231);border-radius: 50%;"></div>
                  </div>
                </li>
                <li class="list-group-item text-muted text-center" *ngIf="notifications?.length == 0">
                  <small>Sin notificaciones por el momento</small>
                </li>
              </ul>
            </perfect-scrollbar>
            <li>
              <a href="javascript: void(0)" mti-color-hover-item class="dropdown-item waves-light text-center"
                 mdbWavesEffect
                 (click)="notificationsModal.modal.show()">
                Ver todas las notificaciones
              </a>
            </li>

          </ul>
        </li>
        <li class="nav-item item-movil-admin">
          <a class="nav-link waves-light" mdbWavesEffect (click)="accountComponent.modal.show()">
            <i class="fa fa-user"></i>
            <span class="clearfix d-none d-sm-inline-block"> &nbsp;Hola, {{ username }}</span>
          </a>
        </li>
        <li class="nav-item item-movil-admin">
          <a class="nav-link waves-light" mdbWavesEffect (click)="signOut()">
            <i class="fa fa-sign-out-alt mr-1"></i>
            <span class="clearfix d-none d-sm-inline-block">Cerrar sesión</span>
          </a>
        </li>
      </ul>
    </navlinks>
  </mdb-navbar>
  <!--/. Navbar -->

</header>
<!--/.Double navigation-->
<app-data-user #accountComponent></app-data-user>
<app-notifications-modal #notificationsModal
                         [borrarNotificacionDeListaYDisminuirContador]="borrarNotificacionDeListaYDisminuirContadorFunc">
</app-notifications-modal>
